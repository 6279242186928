exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-chi-siamo-tsx": () => import("./../../../src/pages/chi-siamo.tsx" /* webpackChunkName: "component---src-pages-chi-siamo-tsx" */),
  "component---src-pages-contattaci-tsx": () => import("./../../../src/pages/contattaci.tsx" /* webpackChunkName: "component---src-pages-contattaci-tsx" */),
  "component---src-pages-eventi-tsx": () => import("./../../../src/pages/eventi.tsx" /* webpackChunkName: "component---src-pages-eventi-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-la-struttura-tsx": () => import("./../../../src/pages/la-struttura.tsx" /* webpackChunkName: "component---src-pages-la-struttura-tsx" */),
  "component---src-pages-servizi-tsx": () => import("./../../../src/pages/servizi.tsx" /* webpackChunkName: "component---src-pages-servizi-tsx" */)
}

